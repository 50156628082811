<template>
  <div class="content-component" @click="handleClearSelection">
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create Open Formtype") }}</el-button
    >
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :show-content-view-switcher="true"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              :class="contentViewClasses"
              v-if="openFormTypes.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(openFormType, index) in openFormTypes"
                :item-index="index"
                :key="'_content_open_form_type_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [openFormType[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="
                  openFormType.is_archived === 1 ? false : true
                "
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-open-form-grey.svg"
                :card-text="openFormType.oft_name"
                :content-list-item="openFormType"
                :fields-to-display="displayFields"
                @delete="
                  handleDelete(
                    openFormType,
                    'OpenFormType',
                    openFormType.open_form_type_id
                  )
                "
                @select="handleSelect(index, openFormType)"
                @edit="handleEdit(index, openFormType)"
                @checked-content="handleSelectMultiple($event)(openFormType)"
                @toggle-select-all="
                  handleToggleSelectAll(openFormTypes, ...arguments)
                "
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              >
                <template #nlp_engine>
                  <div>
                    {{ getNlpEngineTypeLabel(openFormType.nlp_engine) }}
                  </div>
                </template>
              </content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Open Forms") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";

export default {
  name: "OpenFormTypesContentsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  selectedItemIndex: {
    required: false,
    default: null
  },
  data() {
    return {
      additionalValidateRoute: "open-forms",
      sortByColumns: ["oft_name", "open_form_type_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "oft_name",
          display_name: __("Name")
        },
        {
          field_name: "oft_description",
          display_name: __("Description")
        },
        {
          field_name: "nlp_engine",
          display_name: __("NLP Engine"),
          use_slot: true
        }
      ]
    };
  },

  computed: {
    ...mapState("openFormTypes", {
      openFormTypes: state => state.openformtypes,
      loading: state => state.loading
    }),
    ...mapGetters("openFormTypes", {
      getNlpEngineTypeLabel: "getNlpEngineTypeLabel"
    }),
    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
  },

  methods: {
    ...mapActions("openFormTypes", {
      contentAPI: "getOpenFormTypes"
    })
  }
};
</script>
<style lang="scss">
.content-openformtype-list {
  .nlp_engine {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";

::v-deep .el-select {
  input {
    height: 44px;
    border-color: #f5f5f8;
    color: #a0a8b5;
  }

  width: 90px;
  .el-input.is-focus .el-input__inner {
    border-color: var(--theme-color) !important;
  }

  .el-input__inner:hover {
    border-color: var(--theme-color) !important;
  }

  .el-select-dropdown__item.selected {
    color: var(--theme-color) !important;
  }

  .el-input__inner:focus {
    border-color: var(--theme-color) !important;
  }
}
</style>
